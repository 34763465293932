import { useState, useEffect } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';

export default function MakeMyGift({ newsletter }) {
    const size = useWindowSize();
    // const [givingLink, setGivingLink] = useState('/give?reference_code=STNETIN23');

    // useEffect(() => {
    //     const givingCookie = document.cookie.split('; ').find(row => row.startsWith('don-w-bib'));

    //     if (givingCookie) {
    //         const givingCookieValue = givingCookie.replace('don-w-bib=', '');
    //         if (givingCookieValue === 'don-w-bib-a') setGivingLink('/give?utm_source=home&utm_medium=website&reference_code=STNET-wo-bib');
    //         else setGivingLink('/giving?utm_source=home&utm_medium=website&reference_code=STNET-w-bib');
    //     } else {
    //         const splitTest = Math.random() < 50 / 100 ? 1 : 0;

    //         if (splitTest === 1) {
    //             setGivingLink('/give?utm_source=home&utm_medium=website&reference_code=STNET-wo-bib');
    //             document.cookie = 'don-w-bib=don-w-bib-a; max-age=86400; path=/';
    //         } else {
    //             setGivingLink('/giving?utm_source=home&utm_medium=website&reference_code=STNET-w-bib');
    //             document.cookie = 'don-w-bib=don-w-bib-b; max-age=86400; path=/';
    //         }
    //     }
    // }, []);

    // We are either displaying the Donate link as a button or just text
    const conditionalClasses =
        !newsletter || size.width <= 640
            ? 'hover:bg-black hover:text-white dark:text-black dark:hover:text-white bg-yellow transition duration-500 ease-in-out px-5 font-normal'
            : 'pr-4 ml-1 hidden sm:block font-medium text-[#222] text-lg dark:text-white hover:underline';
    return (
        <div className="gift">
            <Link className={cx(['gift-cta whitespace-pre rounded-3xl py-2 font-proxima text-black', conditionalClasses])} href={'/give?reference_code=STNETIN23'}>
                Donate
            </Link>
        </div>
    );
}
